import { Component } from '@angular/core';
import { DataPreloaderService } from './core/services/data-preloader.service';
import { GlobalEventsService } from './core/services/global-events.service';
import { PwaService } from './core/services/pwa.service';
import { OneSignalService } from './core/services/one-signal.service';

import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet]
})
export class AppComponent {
  title = 'Nightrush';

  constructor(
    public loader: DataPreloaderService,
    private _pwa: PwaService,
    private _globalEvents: GlobalEventsService,
    private _oneSignal: OneSignalService
  ) {
    this._globalEvents.initGlobalEvent();
    this._pwa.registerServiceWorker();
  }
}
