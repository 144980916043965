import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { devDomain } from 'config/dev.domain';
import { Observable, of, ReplaySubject, from } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { LIB_SRC, SsPaymentConfig } from './ss-payments-config';
import { SsPaymentsRequester } from './ss-payments-requester';
import { CustomRoutes, PaymentAction, PaymentMethod, SsPaymentsModuleConfig } from './ss-payments-types';
import { PlatformService } from '../../services/platform.service';
import { PaymentsLibType } from '../ss-payments-v2/ss-payment-types';

declare var PaymentsAPI: PaymentsLibType;

@Injectable()
export class SsPaymentsService {

  /**
   * List of app hosts from which request urls should not be modified
   * @private
   */
  private _ignoredHosts = [
    'https://localhost:4200',
    'https://stage.nightrush.com',
    'https://dev.nightrush.com',
    ...devDomain
  ];

  /**
   * Is ready to use source
   */
  private _ready$: ReplaySubject<boolean> = new ReplaySubject(1);

  /**
   * Custom requester for Ss Payments API
   */
  private _requester = new SsPaymentsRequester(this._resolveHost());

  /**
   * Is library ready to use
   */
  public ready$: Observable<boolean> = this._ready$.asObservable();

  /**
   * List of payment methods with own images
   * @readonly
   */
  private readonly CUSTOM_PAYMENT_IMG = ['trustly'];

  constructor(
    private _platform: PlatformService,
    @Inject(SsPaymentConfig)
    private _config: SsPaymentsModuleConfig,
    @Inject(DOCUMENT)
    private _document: Document
  ) {
    // this.loadApiLibrary();
  }

  /**
   * Load api library script
   */
  loadApiLibrary() {
    const script: HTMLScriptElement = this._document.createElement('script');
    script.onload = (e: Event) => requestAnimationFrame(() => {
      this._ready$.next(true);
    });
    script.src = this._resolveHost() + LIB_SRC;

    this._document.head.appendChild(script);
  }

  /**
   * Fetch payment methods list
   *
   * @param currency
   */
  fetchMethods(currency: string): Observable<any[]> {
    return of()
    // return this.ready$.pipe(
    //   first(),
    //   switchMap(() => fromPromise(PaymentsAPI.fetchPaymentMethods(currency, this._requester))),
    //   map((list: any[]) => list.filter(method => (PaymentsAPI.SUPPORTED_PROVIDERS || []).includes(method.provider))),
    //   map((list: any[]) => list.map(method => ({
    //     ...method,
    //     img: this._resolveImage(method)
    //   })))
    // );
  }



  /**
   * Build object that contain information to render payment form and additional information.
   *
   * @param currency
   * @param action
   * @param method
   * @param channelIdentifier
   */
  buildPaymentPlan(currency: string, action: PaymentAction, method: any, channelIdentifier: string = null): Observable<any> {
    return this.ready$
  /*    .pipe(
      first(),
      switchMap(() => fromPromise(PaymentsAPI.buildPaymentPlan(currency, action, method, channelIdentifier, this._requester)))
    );*/
  }

  /**
   * Tells whether given paymentMethod is enabled for the action or not.
   *
   * @param action
   * @param method
   */
  isPaymentMethodEnabled(action: PaymentAction, method): boolean {
    return true
    // return PaymentsAPI.isPaymentMethodEnabled(action, method);
  }

  /**
   * Tells whether given paymentMethod has saved methods for the action or not.
   *
   * @param action
   * @param method
   */
  savedChannels(action: PaymentAction, method): Array<any> {
    return []
    // return PaymentsAPI.savedChannels(action, method);
  }

  /**
   * Tells whether user can process new payments through this payment method.
   *
   * @param action
   * @param method
   */
  // isNewPaymentEnabled(action: PaymentAction, method): boolean {
  //   return PaymentsAPI.isNewPaymentEnabled(action, method);
  // }

  /**
   * Return list of saved accounts for provided payment method
   *
   * @param action
   * @param method
   */
  savedAccounts(action: PaymentAction, method): Array<any> {
    return (method[action + '_saved_methods'] || []).map(savedMethod => ({
      ...savedMethod,
      id: method.id,
    }));
  }

  /**
   * Submit payment form.
   *
   * @param plan
   * @param formData
   * @param customRoutes
   */
  submitForm(plan, formData, customRoutes: CustomRoutes = {}, changeCustomRoutes: CustomRoutes = {}) {
    return this.ready$
    //   .pipe(
    //   first(),
    //   switchMap(() => fromPromise(PaymentsAPI.submitForm(plan, formData, this._requester, {...customRoutes, ...changeCustomRoutes})))
    // );
  }

  /**
   * Returns payment method logo URL
   *
   * @param method
   * @private
   */
  private _resolveImage(method: PaymentMethod): string {
    if (this.CUSTOM_PAYMENT_IMG.includes(method.brand)) {
      return `/assets/svg/payment-methods/color/${method.brand}.svg`;
    }
    return method.type === 'crypto'
      ? `https://cdn2.softswiss.net/logos/payments/color/${method.currency}_${method.brand}.svg`
      : `https://cdn2.softswiss.net/logos/payments/color/${method.provider}-${method.brand}.svg`;
  }

  private _resolveHost() {
    return this._platform.isBrowser &&
    !this._ignoredHosts.some(item => item.includes(window.location.host)) ?
      window.location.origin.replace(/(https:\/\/)(www.|new.|stage-new.)/, 'https://') :
      this._config.host;
  }

}
