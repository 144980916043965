import { createInitializerQueueList } from './initializer-manager';
import { APP_INITIALIZER, Injector, Provider } from '@angular/core';
import { DecoratorInjector } from '../../core/helpers/decorator-service.service';


/**
 * The `initApp` function initializes the DecoratorService with the provided Injector.
 * @param {Injector} injector - The `injector` parameter in the `initApp` function is of type
 * `Injector`. It is used to provide dependencies to the application components. The `Injector` is a
 * dependency injection container that resolves dependencies for classes and services in Angular
 * applications.
 * @returns A function is being returned.
 */
export function initApp(injector: Injector) {
  return () => {
    DecoratorInjector.injector = injector;
  };
}


export const provideAppInitializer = () => {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [Injector],
      multi: true
    },
    ...parallelAppInitializer,
  ];
};

const parallelAppInitializer: Provider[] = createInitializerQueueList([

]);
