import { AppComponent } from './app.component';
import { provideRouter, Routes, withInMemoryScrolling } from '@angular/router';
import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SsWebSocketsModule } from './core/vendor/ss-web-sockets/ss-web-sockets.module';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { ContentUpdaterService } from './core/services/content-updater/content-updater.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { GlobalErrorHandler } from './core/helpers/global-error-handler';
import { CacheControlInterceptor } from './core/interceptors/cache-control.interceptor';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-unificator/services';
import { provideAppInitializer } from './core/initializer/initializer-queue';
import { HostRequestsInterceptor } from './core/interceptors/host-request.interceptor';
import { DecimalPipe } from '@angular/common';
import { environment } from '../environments/environment';
import { SsPaymentsModule } from './core/vendor/ss-payments/ss-payments.module';
import { provideABTest } from './core/ab-test/ab-test';
import { abTestList } from './core/ab-test/ab-test.data';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      { path: '', loadChildren: () => import('./page/page.module').then(m => m.PageModule) },
    ],
  },
];

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(
      withInterceptorsFromDi(),
      withFetch(),
    ),
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top'
      }),
    ),
    provideAppInitializer(),
    provideAnimations(),
    provideNgxMask(),
    provideABTest(abTestList),
    importProvidersFrom(
      BrowserModule.withServerTransition({ appId: 'nr' }),
      FormsModule,
      CookieService,
      HammerModule,
      ContentUpdaterService,
      BrowserAnimationsModule,
      ReactiveFormsModule,
      SsWebSocketsModule,
      NgxMaskDirective,
      DecimalPipe,
      SsPaymentsModule.forRoot({
        host: environment.ss_host
      }),
    ),
    DecimalPipe,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheControlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HostRequestsInterceptor,
      multi: true,
    },
  ],
};
